//=================================================
// About
//=================================================

.about-img {
	.img {
		min-height: 450px;

		&.object-fit {
			&:before {
				padding-top: 50%;
			}
		}
	}

	//@media #{$media_sm} {
	//	.img {
	//		min-height: 300px;
	//
	//		&.object-fit {
	//			&:before {
	//				padding-top: 50%;
	//			}
	//		}
	//	}
	//}
}
