//=================================================
// Team
//=================================================

.team-member {
  @extend .style-default;

  .img {
    display: flex;
    justify-content: center;
    width: 60%;
    height: auto;
    margin-bottom: 15px;
    @media #{$media_sm} {
      width: 100%;
    }
  }

  .team-member-content {
    padding-top: $space_xs;

    .team-member-position {
      margin-bottom: $space_xs - 0.465rem;
    }

    .team-member-t-head {
      color: $body_headline_color;
    }
  }

  .team-member-nav-items {
    padding-top: $space_xs;

    ul {
      li {
        margin-right: 1.368rem;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-size: 0.947rem;
        }
      }
    }
  }
}
