/* YButton.css */

.yButton {
    position: fixed !important;
    width: 100px !important;
    height: 100px !important;
    box-sizing: border-box !important;
    right: 30px !important;
    bottom: 30px !important;
    z-index: 10000;
}

.yButtonBackground {
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    width: 100px !important;
    height: 100px !important;
    border-radius: 100% !important;
    background-color: #6D7978;
    opacity: 0.8 !important;
    box-sizing: border-box !important;
}

.yButtonWave {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 50% !important;
    border-color: #6D7978;
    color: #6D7978;
    border: 2px solid !important;
    width: 105%;
    height: 105%;
    opacity: 0.9 !important;
    box-sizing: border-box !important;
    animation: wave-stroke 2s infinite cubic-bezier(0.37, 0, 0.8, 0.77);
}

.yButtonText {
    position: absolute !important;
    top: 25px !important;
    left: 5px !important;
    right: 5px !important;
    border-radius: 50% !important;
    width: 90px !important;
    height: 50px !important;
    color: #fff !important;
    font-size: 12px !important;
    line-height: 25px !important;
    font-weight: 400 !important;
    text-align: center !important;
    vertical-align: middle !important;
    letter-spacing: 1.5px !important;
    font-family: play, sans-serif !important;
    box-sizing: border-box !important;
}

@-webkit-keyframes wave-stroke {
    100% {
        width: 200%;
        height: 200%;
        border-color: transparent;
        opacity: 0
    }
}

@-moz-keyframes wave-stroke {
    100% {
        width: 200%;
        height: 200%;
        border-color: transparent;
        opacity: 0
    }
}

@-o-keyframes wave-stroke {
    100% {
        width: 200%;
        height: 200%;
        border-color: transparent;
        opacity: 0
    }
}

@keyframes wave-stroke {
    100% {
        width: 200%;
        height: 200%;
        border-color: transparent;
        opacity: 0
    }
}


.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10001; /* Ensure it's above other content */
}

.modalContent {
    position: relative;
    background: white;
    padding: 10px;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
}

.modalClose {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}